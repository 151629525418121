import PropTypes from "prop-types";
import { useState } from "react";
import NavbarLogo from "components/shared/adventures/NavbarLogo";
import NavbarSmallMenu from "components/shared/adventures/NavbarSmallMenu";
import NavbarSmallMenuButton from "components/shared/adventures/NavbarSmallMenuButton";
import NavbarSmallBack from "components/shared/adventures/NavbarSmallBack";
import styles from "components/shared/adventures/NavbarSmall.module.scss";

export default function NavbarSmall({
  parentName,
  parentPath,
  links,
  smallPrimary,
  smallSecondary,
  smallTertiary,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className={styles.component}>
        <div className={styles.grid}>
          <div className={styles.primary}>
            {smallPrimary === "logo" && <NavbarLogo />}
            {smallPrimary === "back" && (
              <NavbarSmallBack
                parentName={parentName}
                parentPath={parentPath}
              />
            )}
          </div>
          <div className={styles.secondary}>
            {smallSecondary === "logo" && <NavbarLogo />}
          </div>
          <div className={styles.tertiary}>
            {smallTertiary === "menu" && (
              <NavbarSmallMenuButton setIsOpen={setIsOpen} showOpen />
            )}
          </div>
        </div>
      </div>
      <NavbarSmallMenu
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleClose={handleClose}
        links={links}
      />
    </>
  );
}

NavbarSmall.propTypes = {
  parentName: PropTypes.string,
  parentPath: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      text: PropTypes.string,
      newTab: PropTypes.bool,
      inMenu: PropTypes.bool,
    })
  ),
  smallPrimary: PropTypes.string,
  smallSecondary: PropTypes.string,
  smallTertiary: PropTypes.string,
};
