import PropTypes from "prop-types";
import cn from "classnames";
import NavbarLogo from "components/shared/adventures/NavbarLogo";
import NavbarBigMenu from "components/shared/adventures/NavbarBigMenu";
import NavbarBigLinks from "components/shared/adventures/NavbarBigLinks";
import styles from "components/shared/adventures/NavbarBig.module.scss";

export default function NavbarBig({
  links,
  bigPrimary,
  bigSecondary,
  bigTertiary,
  bigNarrow,
}) {
  return (
    <div className={cn(styles.component, bigNarrow && styles.narrow)}>
      <div className={styles.grid}>
        <div className={styles.primary}>
          {bigPrimary === "links" && <NavbarBigLinks links={links} />}
          {bigPrimary === "logo" && <NavbarLogo />}
        </div>
        <div className={styles.secondary}>
          {bigSecondary === "logo" && <NavbarLogo />}
        </div>
        <div className={styles.tertiary}>
          {bigTertiary === "menu" && <NavbarBigMenu />}
        </div>
      </div>
    </div>
  );
}

NavbarBig.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      text: PropTypes.string,
      newTab: PropTypes.bool,
      inMenu: PropTypes.bool,
    })
  ),
  bigPrimary: PropTypes.string,
  bigSecondary: PropTypes.string,
  bigTertiary: PropTypes.string,
  bigNarrow: PropTypes.bool,
};
