/* eslint-disable react/jsx-no-useless-fragment */
import PropTypes from "prop-types";
import cn from "classnames";
import React, { useState } from "react";
import { RemoveScroll } from "react-remove-scroll";
import { use100vh } from "react-div-100vh";
import useUserAuth from "helpers/hooks/useUserAuth";
import Container from "solar/components/Container";
import ChatButton from "components/shared/adventures/ChatButton";
import NavbarSmallMenuItem from "components/shared/adventures/NavbarSmallMenuItem";
import NavbarSmallMenuButton from "components/shared/adventures/NavbarSmallMenuButton";
import styles from "components/shared/adventures/NavbarSmallMenu.module.scss";

export default function NavbarSmallMenu({
  isOpen,
  setIsOpen,
  handleClose,
  links = [],
}) {
  const [menuExpanded, setMenuExpanded] = useState(false);
  const authenticated = useUserAuth();
  const windowHeight = use100vh();
  const generalLinks = links.filter((link) => !link.authenticated);
  const authenticatedLinks = links.filter((link) => link.authenticated);

  function renderItem(link) {
    if (link.isMenuButton) {
      if (menuExpanded) {
        return null;
      }
      return (
        <li>
          <div>
            <button
              type="button"
              className={styles.link}
              onClick={() => {
                setMenuExpanded(true);
              }}
            >
              {link.text}
            </button>
          </div>
        </li>
      );
    }

    if (link.unauthenticated && authenticated) {
      return null;
    }

    if (link.isChat) {
      return (
        <li>
          <div>
            <ChatButton
              type="text-only"
              text={link.text}
              className={styles.link}
            />
          </div>
        </li>
      );
    }

    return (
      <NavbarSmallMenuItem
        key={link.text}
        href={link.href}
        text={link.text}
        handleClose={handleClose}
        isHidden={link.inMenu && !menuExpanded}
      />
    );
  }

  return (
    <RemoveScroll enabled={isOpen}>
      <div
        className={cn(styles.component, isOpen && styles.showing)}
        style={{ height: windowHeight }}
      >
        <Container>
          <div className={styles.header}>
            <NavbarSmallMenuButton setIsOpen={setIsOpen} />
          </div>

          <ul className={styles.links}>
            {generalLinks.map((link) => (
              <React.Fragment key={link.text}>
                {renderItem(link)}
              </React.Fragment>
            ))}
          </ul>

          {authenticated && (
            <>
              <div className={styles.divider} />
              <ul className={cn(styles.links, styles.secondary)}>
                {authenticatedLinks.map((link) => (
                  <React.Fragment key={link.text}>
                    {renderItem(link)}
                  </React.Fragment>
                ))}
              </ul>
            </>
          )}
          <div className={styles.divider} />

          <div className={styles.call}>
            <span>Text or call to plan your dream trip</span>
            <a href="tel:1-415-534-1527" className={styles.phone}>
              (415) 534-1527
            </a>
          </div>
        </Container>
      </div>
    </RemoveScroll>
  );
}

NavbarSmallMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      text: PropTypes.string,
      newTab: PropTypes.bool,
      inMenu: PropTypes.bool,
      isChat: PropTypes.bool,
      authenticated: PropTypes.bool,
      unauthenticated: PropTypes.bool,
    })
  ),
};
