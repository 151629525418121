import PropTypes from "prop-types";
import IconClose from "solar/icons/IconClose";
import IconMenu from "solar/icons/IconMenu";
import styles from "components/shared/adventures/NavbarSmallMenuButton.module.scss";

export default function NavbarSmallMenuButton({ setIsOpen, showOpen }) {
  return (
    <div>
      {showOpen ? (
        <button
          onClick={() => {
            setIsOpen(true);
          }}
          type="button"
          className={styles.button}
        >
          <IconMenu />
        </button>
      ) : (
        <button
          onClick={() => {
            setIsOpen(false);
          }}
          type="button"
          className={styles.button}
        >
          <IconClose />
        </button>
      )}
    </div>
  );
}

NavbarSmallMenuButton.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
  showOpen: PropTypes.bool,
};
