import PropTypes from "prop-types";
import cn from "classnames";
import Link from "next/link";
import styles from "components/shared/adventures/NavbarBigLink.module.scss";

export default function NavbarBigLink({
  href,
  text,
  newTab = true,
  inMenu = false,
}) {
  return (
    <span className={cn(inMenu && styles.menu)}>
      {newTab ? (
        <a href={href} target="_blank" rel="noreferrer" className={styles.link}>
          {text}
        </a>
      ) : (
        <Link prefetch={false} href={href} className={styles.link}>
          {text}
        </Link>
      )}
    </span>
  );
}

NavbarBigLink.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  newTab: PropTypes.bool,
  inMenu: PropTypes.bool,
};
