import PropTypes from "prop-types";
import { useRouter } from "next/router";
import styles from "components/shared/adventures/NavbarSmallMenuItem.module.scss";

export default function NavbarSmallMenuItem({
  href,
  text,
  handleClose = null,
  external = false,
  isHidden = false,
}) {
  const router = useRouter();

  const handleClick = () => {
    router.push(href);
    handleClose();
  };

  return (
    <div>
      {!isHidden && (
        <li>
          {external ? (
            <a
              href={href}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              {text}
            </a>
          ) : (
            <button type="button" onClick={handleClick} className={styles.link}>
              {text}
            </button>
          )}
        </li>
      )}
    </div>
  );
}

NavbarSmallMenuItem.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  handleClose: PropTypes.func,
  external: PropTypes.bool,
  isHidden: PropTypes.bool,
};
