import PropTypes from "prop-types";
import Head from "next/head";
import { formatURL } from "helpers/cloudfrontURLFormatting";

export default function Meta({
  title = "Thermal",
  path = "/",
  description = "More than empty lineups",
  imageOgUrl = "https://www.thermal.travel/meta/default.jpg",
  imageTwitterUrl = null,
  imageType = "image/jpeg",
}) {
  function shorten(str, maxLen, separator = " ") {
    if (str.length <= maxLen) return str;
    return str.substr(0, str.lastIndexOf(separator, maxLen));
  }

  const brandedTitle = title === "Thermal" ? title : `${title} | Thermal`;

  const basePath = "https://www.thermal.travel"; // rt: this should probably be pulled from elsewhere?
  const url = basePath + path;

  const ogTitle = shorten(title, 70);

  const seoDescription =
    description?.length > 155 ? `${shorten(description, 155)}...` : description;
  const ogDescription =
    description?.length > 195 ? `${shorten(description, 195)}...` : description;

  const processedImageOg = formatURL(imageOgUrl, {
    resize: { width: 2160, height: 2160 },
    toFormat: "jpeg", // og images must not be webp
  });

  const processedImageTwitter = formatURL(imageTwitterUrl || imageOgUrl, {
    resize: { width: 1200, height: 628 },
    toFormat: "jpeg", // og images must not be webp
  });

  return (
    <Head>
      {/* Base */}
      <title>{brandedTitle}</title>
      <meta name="description" content={seoDescription} />

      {/* SEO */}
      <link rel="canonical" href={url} />

      {/* Open graph */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={ogDescription} />
      <meta property="og:image" content={processedImageOg} />
      <meta property="og:image:width" content={2160} />
      <meta property="og:image:height" content={2160} />
      <meta property="og:image:type" content={imageType} />

      {/* Facebook */}
      <meta property="fb:app_id" content="291872068881250" />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:site" content="@thermal.travel" />
      <meta property="twitter:image" content={processedImageTwitter} />
    </Head>
  );
}

Meta.propTypes = {
  path: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  imageOgUrl: PropTypes.string,
  imageTwitterUrl: PropTypes.string,
  imageType: PropTypes.string,
};
